
import {
  Vue,
  Component,
  Prop,
  PropSync,
} from 'vue-property-decorator';
import {
  FormMetadata,
  Property,
  Value,
  File,
} from '@/types/vjsf';
import Message from '@/components/mixins/Message.vue';

// Lazy loading imports
const Tooltip = () => import(
  /* webpackChunkName: "form-tooltip" */
  /* webpackPrefetch: false */
  '@/components/Tooltip/Tooltip.vue'
);

@Component({
  name: 'FilesField',
  components: {
    Tooltip,
  },
})
export default class FilesField extends Vue {
  @PropSync('value', { required: true })
  private file!: Value;

  @Prop({ required: true })
  private readonly property!: Property;

  @Prop({ required: true })
  private readonly serverLocale!: string;

  @Prop({ required: true })
  private readonly id!: string;

  @Prop({ required: true })
  private readonly required!: boolean;

  @Prop()
  private readonly metadata: FormMetadata | undefined;

  private files: Array<File> = [];

  private loading = false;

  private refresh = 0;

  protected mounted(): void {
    if (this.metadata?.files !== undefined) {
      this.files.push(this.metadata?.files as File);
    }
  }

  private uploadFiles(filesObject: File): void {
    this.loading = true;

    const variables = {
      file: filesObject,
    };

    import('@/graphql/mutations/upload-file')
      .then(({ default: mutation }) => this.$apollo.mutate({
        mutation,
        variables,
      }))
      .then((response) => {
        this.file = response.data.upload.id;
        this.files = [
          {
            [response.data.upload.id]: {
              id: response.data.upload.id,
              name: response.data.upload.name,
            },
          },
        ];
      })
      .then(() => {
        this.refresh += 1;
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private deleteFile(id: string): void {
    import('@/graphql/mutations/delete-file')
      .then(({ default: mutation }) => this.$apollo.mutate({
        mutation,
        variables: {
          id,
        },
      }))
      .then((response) => {
        if (response.data.successful) {
          this.file = null;
          this.files = [];
        }
      })
      .then(() => {
        this.refresh += 1;
      });
  }

  private downloadFile(id: string) {
    let fileName = '';
    let fileType = '';

    import('@/graphql/queries/download-file')
      .then(({ default: query }) => this.$apollo.query({
        query,
        variables: {
          id,
        },
      }))
      .then((response) => {
        const base64 = response.data.file.content;
        fileName = response.data.file.filename;
        fileType = 'image/jpg';
        return fetch(`data:${fileType};base64,${base64}`);
      })
      .then((res) => res.blob())
      .then((blobImage) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blobImage);
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      });
  }
}
